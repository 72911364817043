.contenedorPrincipalCardCarrito {
    display: flex;
    border-style: solid;
    border-color: var(--colorSecundario);
    border-width: 2px 2px 0px 2px;
    max-width: 100%;
    background: linear-gradient(to right, white 0%, white 60%, var(--colorPrimario) 60%, var(--colorPrimario) 100%);
    z-index: 11;
    position: relative;
    height: 20rem;
    flex-direction: row;
}

.imagenYCodigoCardCarrito {
    width: 60%;
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    background-image: url("../../Imagenes/marcaDeAgua.webp");
    background-size: cover;
}

.imagenCardCarritoContainer {
    width: 100%;
    height: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imagenCardCarrito {
    width: auto;
    height: 100%;
    object-fit: contain;
    max-width: 100%;
    cursor: pointer;
    transition: scale 0.2s;
    user-select: none;
}

.imagenCardCarrito:hover {
    scale: 1.05;
}

.kgCardCarrito {
    text-align: center;
    font-weight: 500;
}

.codigoYDetalleCardCarritoContainer {
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.codigoYDetalleCardCarrito {
    font-weight: 600;
    text-align: center;
    max-height: 100%;
    overflow: hidden;
}

.codigoYDetalleCardCarrito span {
    color: var(--colorRojo);
}

.restoCardCarrito {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    justify-content: space-between;
}

.cantidadCardCarrito {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 2.6rem;
}

.cantidadCardCarrito button {
    width: 2rem;
    height: 2rem;
    border: none;
    background-color: var(--colorRojo);
    color: white;
    font-weight: 500;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.08s;
}

.cantidadCardCarrito button:active {
    transform: scale(0.95);
}

.botonSumarCardCarrito {
    border-radius: 0 3px 3px 0;
    margin-right: 1rem;
}

.botonRestarCardCarrito {
    border-radius: 3px 0 0 3px;
}

.cantidadCardCarrito div {
    width: 2rem;
    height: 2rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cantidadCardCarrito p {
    font-weight: 600;
    font-size: 2rem;
}

.eliminarCardCarrito {
    cursor: pointer;
    transition: color 0.2s;
    padding-bottom: 0.2rem;
}

.eliminarCardCarrito:hover {
    color: var(--colorRojo);
}

.colorCardCarritoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 11rem;
    min-width: 9rem;
}

.muestraColorCardCarrito {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2.5rem;
}

.textoColorCardCarrito {
    font-size: 1.2rem;
    font-weight: 600;
}

.colorCardCarrito {
    font-weight: 700;
}

.precioContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.textoPrecioCardCarrito {
    font-size: 1.2rem;
    font-weight: 500;
}

.precioProductoCardCarrito {
    font-weight: 700;
    font-size: 1.5rem;
    background-color: white;
    width: 11rem;
    border-radius: 3px 3px 0 0;
    display: flex;
    justify-content: center;
    padding-top: 0.2rem;
}

.precioUnitarioCardCarrito {
    font-weight: 500;
    font-size: 1.4rem;
    background-color: var(--colorTerciario);
    width: 11rem;
    display: flex;
    justify-content: center;
    border-radius: 0 0 3px 3px;
    padding-top: 0.2rem;
}

@media (max-width: 767px) {
    .contenedorPrincipalCardCarrito {
        height: 27rem;
    }

    .restoCardCarrito {
        padding: 2rem 0;
    }

    .kgCardCarrito {
        font-size: 2rem;
    }

    .codigoYDetalleCardCarrito {
        font-size: 2.2rem;
    }

    .cantidadCardCarrito {
        padding-left: 3.5rem;
        height: 3rem;
        font-size: 3rem;
    }

    .cantidadCardCarrito button {
        width: 4rem;
        height: 4rem;
        font-size: 4rem;
    }

    .cantidadCardCarrito div {
        height: 4rem;
        width: 4rem;
    }

    .cantidadCardCarrito div p {
        font-size: 3rem;
    }

    .eliminarCardCarrito {
        width: 3.5rem;
        height: 3.5rem;
    }

    .eliminarCardCarrito .bi {
        height: 100%;
        width: 100%;
    }

    .textoColorCardCarrito {
        font-size: 2.3rem;
    }

    .colorCardCarritoContainer {
        max-width: 15rem;
        min-width: 13rem;
    }

    .muestraColorCardCarrito {
        height: 3.6rem;
        border-radius: 3px;
    }

    .muestraColorCardCarrito p {
        font-size: 2rem;
    }

    .precioContainer .textoPrecioCardCarrito {
        font-size: 2.2rem;
        font-weight: 600;
    }

    .precioProductoCardCarrito p {
        font-size: 2.3rem;
    }

    .precioUnitarioCardCarrito p {
        font-size: 2.3rem;
    }

    .precioProductoCardCarrito,
    .precioUnitarioCardCarrito {
        min-width: 12rem;
    }

    .precioProductoCardCarrito p,
    .precioUnitarioCardCarrito p {
        font-size: 2.1rem;
    }
}

@media (max-width: 540px) {
    .contenedorPrincipalCardCarrito {
        height: 27rem;
    }

    .restoCardCarrito {
        padding: 2rem 0;
    }

    .kgCardCarrito {
        font-size: 2rem;
    }

    .codigoYDetalleCardCarrito {
        font-size: 2.2rem;
    }

    .cantidadCardCarrito {
        padding-left: 3.5rem;
        height: 3rem;
        font-size: 3rem;
    }

    .cantidadCardCarrito button {
        width: 4rem;
        height: 4rem;
        font-size: 4rem;
    }

    .cantidadCardCarrito div {
        height: 4rem;
        width: 4rem;
    }

    .cantidadCardCarrito div p {
        font-size: 3rem;
    }

    .eliminarCardCarrito {
        width: 3.5rem;
        height: 3.5rem;
    }

    .eliminarCardCarrito .bi {
        height: 100%;
        width: 100%;
    }

    .textoColorCardCarrito {
        font-size: 2.3rem;
    }

    .colorCardCarritoContainer {
        max-width: 15rem;
        min-width: 13rem;
    }

    .muestraColorCardCarrito {
        height: 3.6rem;
        border-radius: 3px;
    }

    .muestraColorCardCarrito p {
        font-size: 2rem;
    }

    .precioContainer .textoPrecioCardCarrito {
        font-size: 2.2rem;
        font-weight: 600;
    }

    .precioProductoCardCarrito,
    .precioUnitarioCardCarrito {
        min-width: 16rem;
    }

    .precioProductoCardCarrito p,
    .precioUnitarioCardCarrito p {
        font-size: 2.4rem;
    }
}

@media (max-width: 280px) {
    .restoCardCarrito {
        background-color: var(--colorTerciario);
    }

    .cantidadCardCarrito {
        padding-left: 0.5rem;
    }
}