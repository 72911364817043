.filtros {
    display: flex;
    flex-direction: column;
    top: 16rem;
    left: 1.4rem;
    user-select: none;
    overflow: auto;
    width: 90%;
    height: calc(100vh - 9rem - 8.1rem);
    background-color: rgba(229, 229, 229, 40%);
    margin-bottom: 0.6rem;
}

.filtros::-webkit-scrollbar {
    width: 0;
}

.scrollerFiltros {
    background-color: white;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    border-radius: 0px 0px 4px 4px;
    transition: scale 0.2s, fill 0.2s, background-color 0.2s;
    user-select: none;
    font-size: 2rem;
    font-weight: 600;
}

.scrollerFiltros.enabled {
    cursor: pointer;
    background-color: var(--colorSecundario);
}

.scrollerFiltros.enabled svg {
    fill: white;
}

.scrollerFiltros.enabled:active {
    transform: scale(0.98);
}

.scrollerFiltros.disabled svg {
    fill: var(--colorTerciario);
}

.scrollerFiltros.admin{
    cursor: pointer;
    transition: transform 0.1s;
    background-color: white;
}

.scrollerFiltros.admin:active{
    transform: scale(0.95);
}

.guardarDestacados{
    fill: black !important;
}

.ultimoLabel {
    margin-bottom: 0;
}

.desplazamientoFiltros {
    position: fixed;
    bottom: 0.5rem;
    left: 9%;
}

.labelAdmin{
    background-color: var(--colorPrimario);
}

.labelSubirImagenTienda{
    cursor: pointer;
}

@media (max-width: 767px) {
    .filtros {
        max-height: calc(100vh - 24rem);
    }

    .filtrosYBusqueda.open {
        width: 40%;
    }

    .filtrosYBusqueda {
        background-color: var(--colorTerciario);
        padding-top: 13rem;
        z-index: 105;
        top: 0 !important;
        height: 140%;
        width: 0;
    }
}

@media (max-width: 540px){
    .filtrosYBusqueda.open {
        width: 65%;
    }
}
