.contenedorPrincipalProcesos{
    height: 100%;
    position: relative;
    z-index: 3;
    padding-bottom: 0.6rem;
}

.tiposProcesoContainer{
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 0.6rem;
}

.atrasProcesos{
    position: fixed;
    top: 10rem;
    left: 21%;
    border: none;
    background-color: rgba(255, 255, 255, 70%);
    width: 10rem;
    height: 4rem;
    border-radius: 10px;
    z-index: 4;
    transition: transform 0.2s;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
}

.atrasProcesos:hover{
    transform: scale(1.05);
}

.atrasProcesos:active{
    transform: scale(0.98);
}

.atrasProcesos svg{
    width: 3rem;
    height: 3rem;
}

.tipoProceso{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.75);
    position: relative;
}

.tipoProceso.anodizados{
    border-radius: 5px 0px 0px 5px;
}

.tipoProceso.pinturas{
    border-radius: 0px 5px 5px 0px;
}

.tipoProceso.anodizados::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
    transform: scale(1.02);
    z-index: -1;
    background-size: cover;
    background-position: center;
    background-image: url('../../../Imagenes/anodizados.webp');
}

.tipoProceso.pinturas::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
    transform: scale(1.02);
    z-index: -1;
    background-size: cover;
    background-position: center;
    background-image: url('../../../Imagenes/pinturas.webp');
}

.tipoProceso:hover::before {
    transform: scale(1.12);
}

.tipoProceso h1{
    font-weight: bold;
    width: 100%;
    text-align: center;
    background-color:/*rgba(255, 255, 255, 50%)*/white;
    color: var(--colorSecundario);
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tipoProceso h1 span{
    height: 1rem;
    width: 2.5rem;
    padding: 0;
    margin: 0 0.5rem 0 0;
}

.proceso{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.5rem;
    padding-top: 1rem;
    margin-right: auto;
}

.proceso:last-child .proceso{
    padding-bottom: 1rem;
}

.colorProceso{
    height: 20rem;
}

.cardProceso{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
}

.cardProceso h1{
    padding: 0 1rem;
    width: 100%;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    background-color: rgba(255, 255, 255, 50%);
}

.textoComunicateConNosotros.textoProcesos{
    width: calc(100% - 1rem);
    margin: 1rem auto 0 auto;
    background-color: var(--colorRojo);
    font-size: 2rem;
    text-align: center;
    font-weight: 500;
    max-height: 3rem;
}

.rowConTexto{
    height: calc(100% - 3rem);
}

.rowSinTexto{
    height: 100%;
}

@media(max-width: 911px){
    .atras{
        top: 12rem;
    }
}

@media(max-width: 820px){
    .tipoProceso.anodizados{
        border-radius: 5px 5px 0px 0px;
    }
    
    .tipoProceso.pinturas{
        border-radius: 0px 0px 5px 5px;
    }

    .tiposProcesoContainer{
        display: flex;
        flex-direction: column;
    }
}

@media(max-width: 767px){
    .contenedorPrincipalProcesos{
        margin-top: 2.6rem;
    }

    .cardProceso h1{
        font-size: 3.3rem;
    }

    .atrasProcesos{
        top: 15rem;
        left: 3%;
        width: 15rem;
        height: 6rem;
    }

    .textoComunicateConNosotros.textoProcesos{
        font-size: 3rem;
    }

    .tipoProceso h1 span{
        height: 1rem;
        width: 2.3rem;
    }
}