.contenedorPrincipalFavoritos{
    width: 40rem;
    position: fixed;
    z-index: 103;
    top: 10rem;
    max-height: 55rem;
    display: flex;
    align-items: end;
    flex-direction: column;
    overflow: hidden;
    right: 1rem;
    top: 2.25rem;
}

.bodyFavoritos{
    height: 0;
    transition: height 0.3s;
    overflow: auto;
    margin-top: 1rem;
    transition: height 0.3s;
    interpolate-size: allow-keywords;
}

.bodyFavoritos.open{
    height: auto;
}

.bodyFavoritos::-webkit-scrollbar{
    width: 0;
}

.tituloFavoritos{
    height: 3.2rem;
    font-size: 1.6rem;
    font-weight: 600;
    background-color: var(--colorSecundario);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 0.2rem;
}

.contenedorBotonFavoritos{
    display: flex;
    flex-direction: column;
}

.botonFavoritos{
    background-color: var(--colorSecundario);
    border-radius: 0px;
    border: none;
    width: 3.5rem;
    height: 3.5rem;
    transition: background-color 0.1s, color 0.1s;
    color: white;
    border-radius: 2px;
    margin-bottom: 0.2rem;
}

.botonFavoritos.open{
    background-color: white;
}

.botonFavoritos.open .bi-heart{
    fill: var(--colorRojo);
}

.cantidadFavoritos, .cantidadEnCarrito{
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(--colorSecundario);
    color: white;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    margin-left: 0.1rem;
}

.botonFavoritos.desktop:hover{
    background-color: white;
}

.botonFavoritos.desktop:hover .bi-heart{
    fill: var(--colorRojo);
} 

@media (max-width: 767px){
    .contenedorPrincipalFavoritos{
        max-height: 115rem;
        position: absolute;
        top: 2rem !important;
        right: 1.5rem;
        width: 55rem;
    }

    .bodyFavoritos{
        width: 100%;
    }

    .tituloFavoritos{
        font-size: 2.2rem;
    }

    .botonFavoritos{
        width: 7rem;
        height: 7rem;
    }

    .botonFavoritos .bi{
        width: 4.5rem;
        height: 4.5rem;
    }

    .cantidadFavoritos, .cantidadEnCarrito{
        width: 3rem;
        height: 3rem;
        font-size: 3rem;
    }
}

@media (max-width: 280px){
    .bodyFavoritos{
        width: 44rem;
    }
}