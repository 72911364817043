.contenedorPrincipalCardProducto {
    margin-top: 15px;
    box-shadow: 0 0 6px 1px gray;
    padding: 1rem;
    padding-bottom: 0;
    background-color: var(--colorPrimario);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url("../../../Imagenes/marcaDeAgua.webp");
    background-size: cover;
}

.contenedorPrincipalCardProducto.sinPrecio {
    padding-bottom: 1rem;
}

.imagenContainerCardProducto {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 11rem;
    padding-top: 1rem;
}

.imagenProducto {
    max-width: 80%;
    max-height: 100%;
    cursor: pointer;
    transition: scale 0.2s;
    user-select: none;
}

.imagenProducto:hover {
    scale: 1.04;
}

.detalleYCod_orig {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    height: 5rem;
    align-items: center;
}

.detalleYCod_orig h3 {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
    min-height: 2.7rem;
}

.kgCantidadYColorContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 4rem;
}

.kgCantidadYColorContainer .boton {
    width: 2rem;
    height: 2rem;
    border: none;
    display: flex;
    align-items: center;
    background-color: var(--colorRojo);
    justify-content: center;
    border-radius: 3px;
    transition: background-color 0.2s ease, color 0.2s ease, transform 0.07s;
    font-weight: 500;
    font-size: 2rem;
    color: white;
}

.kgCantidadYColorContainer .boton:active {
    transform: scale(0.95);
}

.conjuntoCantidadCardProducto {
    width: 33%;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cantidadProducto {
    font-size: 1.9rem;
    width: 2rem;
    text-align: center;
    padding-top: 0.25rem;
}

.precioCardProducto{
    margin-left: -1rem;
    width: calc(100% + 2rem);
    font-size: 1.6rem;
    background-color: white;
    border-radius: 3px;
    z-index: 1;
    text-align: center;
    font-weight: 600;
}

.precioCardProducto.primerContainer{
    margin-top: 0.5rem;
}

.precioCardProducto.segundoContainer {
    border-top: 1px solid;
    border-image: linear-gradient(to right, transparent 0%, transparent 6%, var(--colorSecundario) 7%, var(--colorSecundario) 94%, transparent 95%, transparent 100%);
    border-image-slice: 1;
}

.cod_origContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 1;
    float: right;
    margin-top: -1.3rem;
}

.codOrig {
    color: var(--colorRojo);
}

.colorCardProducto {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33%;
}

.muestraColorCardProducto{
    text-overflow: ellipsis;
    max-width: 100%;
    height: 2rem;
    padding: 0 0.5rem;
    padding-top: 0.2rem;
    border-radius: 2px;
    max-width: 95%;
}

.kgProducto,
.colorCardProducto {
    display: flex;
    justify-content: center;
}

.kgProducto {
    flex-direction: column;
    width: 33%;
}

.kgProducto p {
    font-size: 1.3rem;
}

.colorCardProducto p {
    width: 100%;
    font-size: 1.3rem;
    overflow: hidden;
}


.colorCardProducto p,
.kgProducto p {
    text-align: center;
    margin: 0;
}

.decoracionCardProducto {
    width: 100%;
    height: 2.2rem;
    background-color: var(--colorSecundario);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
}

.contenedorPrincipalCardProducto:hover .decoracionCardProducto {
    background-color: var(--colorRojo);
}

.logoDecoracionCardProducto {
    position: relative;
    height: 1.4rem;
}

.botonAñadirFavoritos {
    width: 2.5rem;
    height: 2.5rem;
    border: none;
    background-color: transparent;
    margin-top: 0.5rem;
    margin-bottom: -4rem;
    float: right;
    position: relative;
    z-index: 1;
    transition: transform 0.1s;
}

.botonAñadirFavoritos .bi-heart{
    transition: fill 0.2s;
}

.botonAñadirFavoritos:active {
    transform: scale(0.95);
}

.botonAñadirFavoritos .bi-heart {
    fill: var(--colorSecundario);
}

.botonAñadirFavoritos .bi-heart:hover {
    fill: var(--colorRojo)
}

.botonAñadirFavoritos .bi-heart-fill {
    fill: var(--colorRojo);
}

.pesoYColorTextoCardProducto {
    font-weight: 700;
}

.eliminarElemento {
    position: absolute;
    top: 8rem;
    border: none;
    cursor: pointer;
    z-index: 99;
    transition: transform 0.2s;
    background-color: transparent;
    right: 1.2rem;
}

.eliminarElemento .bi {
    fill: var(--colorSecundario);
}

.eliminarElemento:hover {
    transform: scale(1.1);
}

.ofertarProducto{
    position: absolute;
    top: 12rem;
    border: none;
    cursor: pointer;
    z-index: 99;
    background-color: transparent;
    right: 1.2rem;
}

.ofertarProducto svg{
    transition: fill 0.2s;
}

.ofertarProducto:hover svg, .ofertarProducto.active svg{
    fill:rgb(42, 153, 32);    
}

.inputDescuento{
    position: absolute;
    top: 12rem;
    right: 4rem;
    height: 2rem;
    width: 3rem;
    z-index: 99;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 700;
    color: var(--colorSecundario);
    border-radius: 0;
    border-width: 2px;
    border-color: var(--colorSecundario)
}

.ocultarPrecio {
    position: absolute;
    top: 12.5rem;
    border: none;
    cursor: pointer;
    z-index: 99;
    transition: transform 0.2s;
    background-color: transparent;
    left: 1.8rem;
}

.ocultarPrecio .bi {
    fill: var(--colorSecundario);
}

.ocultarPrecio:hover {
    transform: scale(1.1);
}

.botonConsultarProducto {
    width: 12rem;
    height: 2.8rem;
    font-size: 1.5rem;
    border: none;
    color: white;
    background-color: var(--colorRojo);
    border-radius: 4px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
    transition: scale 0.2s;
}

.botonConsultarProducto:active {
    transform: scale(0.95);
}

.dropdownCantidad {
    display: inline-block;
    flex-direction: column;
    position: absolute;
    top: 3.5rem;
    z-index: 1;
}

.toggleDropdown {
    height: 3rem;
    border: none;
    font-size: 1.5rem;
    font-weight: 600;
    background-color: var(--colorSecundario);
    color: white;
    width: 14rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 0.2rem;
    border-radius: 3px;
}

.dropdownMenuCantidad {
    --bs-dropdown-min-width: 0;
    width: 100%;
    padding: 0;
    height: 0;
    overflow: hidden;
    transition: height 0.2s;
    display: flex;
    flex-direction: row;
    margin-top: 0.3rem;
}

.dropdownMenuCantidad li {
    display: flex;
    justify-content: center;
    background-color: var(--colorSecundario);
    align-items: center;
    font-size: 1.5rem;
    color: white;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
    padding: 0 0.5rem;
    transition: background-color 0.2s;
}

.dropdownMenuCantidad li:hover {
    background-color: var(--colorSecundario);
    color: white;
}

.dropdownCantidad li.selected {
    background-color: var(--colorRojo);
}

.dropdownMenuCantidad li:first-child {
    margin-right: 0.2rem;
}

.dropdownMenuCantidad li:last-child {
    margin-left: 0.2rem;
}

.dropdownMenuCantidad.desplegado {
    height: 3rem;
}

.espacioCantidad {
    width: 5rem;
}

.flechaDropdownCantidades {
    margin-left: 0.3rem;
    height: 1.2rem;
    width: 1.2rem;
    margin-bottom: 0.2rem;
}

.destacadosContenedor {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 3.5rem;
    left: 1rem;
    align-items: center;
}

.destacadosContenedor button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    z-index: 2;
}

.estrellaDestacados {
    height: 4rem;
    width: 4rem;
}

.flechasDestacados {
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.estrellaDestacados svg {
    height: 4rem;
    width: 4rem;
    fill: var(--colorSecundario);
    float: left;
}

.posicionEnDestacados {
    position: relative;
    font-size: 2rem;
    font-weight: 600;
    width: 4rem;
    position: absolute;
    text-align: center;
    height: 8rem;
    top: 2.8rem;
}

.estrellaDestacados.checked svg {
    fill: rgb(148, 148, 41);
}

.flechasDestacados svg {
    width: 2rem;
    height: 2rem;
    fill: var(--colorSecundario);
}

.flechasDestacados:first-child {
    transform: rotate(180deg)
}

@media (max-width: 1280px) {
    .detalleYCod_orig h3 {
        font-size: 1.8rem;
    }

    .imagenContainerCardProducto {
        height: 14rem;
    }

    .conjuntoCantidadCardProducto .boton {
        width: 2.5rem;
        height: 2.5rem;
        font-size: 2.5rem;
    }

    .cantidadProducto {
        font-size: 2.5rem;
        margin: 0 0.5rem;
    }

    .colorCardProducto p {
        font-size: 1.5rem;
    }

    .kgProducto p {
        font-size: 1.5rem;
    }

    .imagenContainerCardProducto {
        height: 12rem;
    }

    .precioCardProducto {
        font-size: 1.5rem;
    }
}

@media (max-width: 912px) {
    .decoracionCardProducto {
        height: 2.4rem;
    }

    .logoDecoracionCardProducto {
        height: 2rem;
    }

    .imagenContainerCardProducto {
        height: 15rem;
    }

    .botonAñadirFavoritos {
        width: 3.5rem;
        height: 3.5rem;
    }

    .detalleYCod_orig {
        height: 7rem;
        display: flex;
        align-items: center;
    }

    .detalleYCod_orig h3 {
        font-size: 2.3rem;
    }

    .kgProducto p {
        font-size: 1.7rem;
    }

    .conjuntoCantidadCardProducto .boton {
        width: 2.8rem;
        height: 2.8rem;
    }

    .kgCantidadYColorContainer {
        height: auto;
    }

    .kgCantidadYColorContainer .boton {
        font-size: 3rem;
    }

    .cantidadProducto {
        font-size: 2.8rem;
        margin: 0 1rem;
    }

    .colorCardProducto p {
        font-size: 1.8rem;
    }

    .muestraColorCardProducto {
        height: 3rem;
    }

    .precioCardProducto {
        height: 3rem;
        background-color: white;
        font-size: 1.9rem;
    }

    .eliminarElemento {
        top: 12rem;
        right: 1.5rem;
        height: 3rem;
        width: 3rem;
    }
}

@media (max-width: 820px) {
    .imagenContainerCardProducto {
        height: 16rem;
    }

    .botonAñadirFavoritos {
        width: 4rem;
        height: 4rem;
    }

    .kgProducto p {
        font-size: 2rem;
    }

    .conjuntoCantidadCardProducto .boton {
        width: 3rem;
        height: 3rem;
    }

    .cantidadProducto {
        font-size: 3rem;
        margin: 0 1rem;
    }

    .colorCardProducto p {
        font-size: 2rem;
    }

    .precioCardProducto {
        height: 3.2rem;
        font-size: 2rem;
    }

    .eliminarElemento .bi-trash3-fill {
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 767px) {
    .contenedorPrincipalCardProducto {
        margin-top: 0;
        margin-bottom: 2rem;
    }

    .dropdownCantidad {
        top: 5rem;
    }

    .toggleDropdown {
        width: 21rem;
        height: 5rem;
        font-size: 2.2rem;
        justify-content: space-between;
        padding: 0 0.5rem;
    }

    .decoracionCardProducto {
        height: 3.2rem;
    }

    .logoDecoracionCardProducto {
        height: 2.4rem;
    }

    .espacioCantidad {
        width: 10rem;
    }

    .toggleDropdown svg {
        width: 2rem;
        height: 2rem;
        padding-top: 0.5rem;
    }

    .dropdownMenuCantidad.desplegado {
        height: 5rem;
    }

    .dropdownMenuCantidad li {
        height: 5rem;
        width: 14rem;
        font-size: 2.2rem;
    }

    .botonAñadirFavoritos {
        top: 2rem;
        right: 2rem;
        height: 7rem;
        width: 7rem;
    }

    .kgProducto p {
        font-size: 3.5rem;
    }

    .kgCantidadYColorContainer{
        height: 12rem;
    }

    .kgCantidadYColorContainer .boton {
        height: 6.5rem;
        width: 6.5rem;
        font-size: 6.5rem;
    }

    .cantidadProducto {
        font-size: 6rem;
        margin: 0 2rem;
        display: flex;
        justify-content: center;
    }

    .colorCardProducto p {
        font-size: 3.5rem;
    }

    .muestraColorCardProducto {
        height: 5.5rem;
        width: 20rem;
    }

    .botonConsultarProducto {
        margin-top: 3rem;
        height: 4.5rem;
        width: 20rem;
        font-size: 3rem;
    }

    .eliminarElemento {
        top: 13rem;
        right: 3rem;
        margin-top: 3rem;
        margin-left: 2rem;
        height: 7rem;
        width: 7rem;
    }

    .detalleYCod_orig h3 {
        font-size: 4.5rem;
    }

    .eliminarElemento .bi-trash3-fill {
        width: 100%;
        height: 100%;
    }

    .posicionEnDestacados {
        font-size: 4rem;
        width: 4rem;
        height: 8rem;
        top: 6.5rem;
    }

    .precioCardProducto{
        font-size: 4rem;
        height: 6rem;
    }

    .destacadosContenedor {
        top: 6rem;
    }

    .estrellaDestacados {
        height: 8rem;
        width: 8rem;
    }

    .estrellaDestacados svg {
        height: 8rem;
        width: 8rem;
    }

    .flechasDestacados {
        height: 5rem;
        width: 5rem;
    }

    .flechasDestacados svg {
        width: 5rem;
        height: 5rem;
    }

    .imagenContainerCardProducto {
        height: 40rem;
    }

    .ocultarPrecio {
        width: 5rem;
        height: 5rem;
        top: 30rem;
        left: 3rem;
    }

    .ocultarPrecio svg{
        height: 100%;
        width: 100%;
    }
}

@media (max-width: 540px){
    .detalleYCod_orig h3 {
        font-size: 3.2rem;
    }

    .kgCantidadYColorContainer .boton {
        height: 5rem;
        width: 5rem;
        font-size: 5rem;
    }

    .cantidadProducto {
        font-size: 4.5rem;
        margin: 0 1rem;
    }

    .precioCardProducto{
        height: 5rem;
        font-size: 3.1rem;
    }

    .colorCardProducto p {
        font-size: 2.5rem;
    }

    .muestraColorCardProducto {
        height: 4rem;
    }

    .kgProducto p {
        font-size: 2.5rem;
    }

    .botonAñadirFavoritos {
        height: 5rem;
        width: 5rem;
    }

    .eliminarElemento {
        height: 4.5rem;
        width: 4.5rem;
    }
}

@media (max-width: 431px) {
    .detalleYCod_orig h3 {
        font-size: 3.8rem;
    }

    .kgProducto p {
        font-size: 3rem;
    }

    .colorCardProducto p {
        font-size: 3rem;
    }

    .conjuntoCantidadCardProducto .boton {
        width: 5rem;
        height: 5rem;
        font-size: 4.5rem;
    }

    .conjuntoCantidadCardProducto span {
        font-size: 4.2rem;
    }
}

@media (max-width: 430px) {
    .imagenContainerCardProducto {
        height: 28rem;
    }
}

@media (max-width: 412px) {
    .botonConsultarProducto {
        margin-top: 1rem;
    }

    .imagenContainerCardProducto {
        height: 26rem;
    }
}

@media (max-width: 360px) {
    .precioCardProducto{
        font-size: 2.8rem;
        padding-top: 0.4rem;
    }
}


@media (max-width: 411px) and (min-width: 281px) {
    .imagenContainerCardProducto {
        height: 21rem;
    }
}

@media (max-width: 312px){
    .precioCardProducto{
        font-size: 2.7rem;
    }
}

@media (max-width: 280px) {
    .muestraColorCardProducto {
        width: 16rem;
    }

    .kgCantidadYColorContainer {
        height: 11rem;
    }

    .kgProducto p {
        font-size: 2.3rem;
    }

    .colorCardProducto p {
        font-size: 2.3rem;
    }
}